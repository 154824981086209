.home {
  &__hero {
    min-height: calc(100vh - 8rem);
    padding: 0 2rem;
    padding-bottom: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    &--image {
      img {
        width: 90%;
        max-width: 450px;
        margin: 0 auto;
        display: block;
      }
    }

    &--description {
      text-align: center;
      display: grid;
      gap: 0.8rem;

      p {
        font-size: 1.6rem;
      }
    }

    &--link {
      text-align: center;

      h2 {
        &:hover {
        }
      }
    }
  }

  &__carousels {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 6rem;
  }
}
