.page-buttons {
  display: flex;
  justify-content: center;
  gap: 1.2rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    min-width: 3.2rem;
    border-radius: 0.4rem;
    height: 4.8rem;
    background: $middle-green;
    font-size: 1.6rem;
    font-family: $font;
  }

  .pages {
    display: flex;
    gap: 0.8rem;

    &__last {
      display: flex;
      gap: 0.8rem;
      align-items: center;
    }
  }

  .disabled {
    background: rgba($middle-green, 0.25);
  }
}
