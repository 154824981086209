@keyframes icon-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    font-size: 3rem;
    animation: icon-spinner infinite 1s linear;
    color: $middle-green;
  }
}
