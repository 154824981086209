.footer {
  background: $foreground;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  &__social-media {
    display: flex;
    gap: 2rem;

    li {
      font-size: 2.8rem;
    }
  }
}
