.redo-button {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .redo {
    font-size: 2.8rem;
    color: $middle-green;
  }
}
