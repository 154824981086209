.carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  min-height: 240px;

  &__list {
    overflow-x: hidden;
    width: 100%;
    max-width: 1600px;
    display: flex;
    gap: 2rem;
  }
}
