.character-card {
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  border-radius: 1.2rem;
  background: $foreground;
  display: flex;
  transition: transform ease-in-out 300ms;

  &__image {
    width: 50%;
    img {
      width: 100%;
      display: block;
    }
  }

  &__data {
    width: 50%;
    padding: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    h3 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (min-width: 768px) {
    &:hover {
      transform: translate(8px, -8px);
    }
  }
}
