.Not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4rem;

  &__message {
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3.5rem;
    }
  }

  &__link {
    a {
      // border: 4px solid $alert-color;
      font-weight: bold;
      border-radius: 1rem;
      // color: $alert-color;
      padding: 0.5rem 1rem;
      font-size: 3rem;
    }
  }
}
