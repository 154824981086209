.character {
  &-profile {
    padding: 4rem 2rem 2rem;
    max-width: 500px;
    margin: 0 auto;

    .character__display {
      figure {
        display: flex;
        justify-content: center;
        img {
          display: block;
          max-width: 224px;
          border-radius: 50%;
          border: 1.2rem solid $foreground;
        }
      }
      h1 {
        margin-top: 1.2rem;
        font-size: 4rem;
        text-align: center;
      }
    }

    .character__data {
      margin-top: 2rem;
      display: grid;
      gap: 1.2rem;
      p {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .loader-container {
    min-height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8rem;
  }
}
