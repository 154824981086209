@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
// Fotn
$font: "Gloria Hallelujah", sans-serif;

// Colors
$black: #0d0d0d;
$white: #f0f2eb;
$light-green: #a7cb54;
$middle-green: #5cad4a;
$dark-green: #208d45;

$background: $black;
$foreground: #19191a;
$texts: $white;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  min-width: 320px;

  &::-webkit-scrollbar {
    background: $background;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: $middle-green;
    border-radius: 7px;
    border: 3px solid $background;
  }
}

body {
  font-family: $font;
  color: $texts;
  background: $background;
}

a,
li {
  color: inherit;
  text-decoration: none;
  list-style: none;
}

input,
button {
  outline: none;
}

p {
  font-size: 1.2rem;
}

h1,
h2,
h3,
h4 {
  color: $middle-green;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}
